import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import Table from '../components/modules/tables/DataList'
import breakpoints from '../components/breakpoints.js'
import KeyVisual from '../components/modules/visuals/TheKeyVisual'

import kv from '../images/job_offer01.jpg'

export const query = graphql`{
  microcmsCompany {
    id
    name
    president
    business
    address
    tel
    established
    fund
    sales
    employees
    offices
    groups
    email
    url
    map
  }
}`

const NotFoundPage = ({ data }) => {
  data  = data.microcmsCompany
  const list = {
    '会社名': data.name,
    '代表者名': data.president,
    '事業内容': data.business,
    '本社所在地': data.address,
    '電話番号': data.tel,
    '創業': data.established,
    '資本金': data.fund,
    '売上高': data.sales,
    '従業員数': data.employees,
    '事務所': data.offices,
    '関連会社': data.groups,
    'e-mail' : data.email,
    'url': data.url,
  }

  return (
    <Layout>
      <SEO title='Company' />
      <KeyVisual title="COMPANY" image={kv} />
      <Wrapper>
        <Table title='Company Profile' subTitle='会社概要' data={list} />
        <Map src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6415.355966881941!2d136.755526!3d36.489493!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa693d0e45184c88a!2z6YeR5r6k5rmv5raM5rip5rOJIOeZvualveiNmCDliKXpgrjnpZ7mpb1LQUdVUkE!5e0!3m2!1sja!2sjp!4v1587050068020!5m2!1sja!2sjp" width="100%" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" title="google map" />
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.section`
  max-width: 980px;
  margin: 60px auto 0;
  padding: 0px 40px;
  ${breakpoints.lessThan('pc')`
    padding: 0 20px;
  `}
`
const Map = styled.iframe`
margin-bottom: 60px;
border: 0;
`

export default NotFoundPage
